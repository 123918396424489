var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row bg-white p-1 mb-2 border rounded"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-12 d-flex justify-content-between align-items-center mt-2"
  }, [_c('b-form-group', {
    staticClass: "mb-0 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-2"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-auto",
    attrs: {
      "id": "perPageSelect",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-0 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-2"
  }, [_vm._v("Sort")]), _c('b-input-group', {
    staticClass: "w-auto",
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1), _c('b-form-group', {
    staticClass: "mb-0 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-2"
  }, [_vm._v("Search")]), _c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    staticClass: "w-75",
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.filteredItems,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(judul)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.title) + " ")];
      }
    }, {
      key: "cell(deskripsi)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.description) + " ")];
      }
    }, {
      key: "cell(durasiwaktu)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.minustes) + " ")];
      }
    }, {
      key: "cell(video)",
      fn: function fn(data) {
        return [_c('video', {
          attrs: {
            "src": data.item.file,
            "controls": "",
            "width": "200"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "form-check"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.selectedItems,
            expression: "selectedItems"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "checkbox",
            "id": 'checkbox-' + data.index
          },
          domProps: {
            "value": data.item.id,
            "checked": Array.isArray(_vm.selectedItems) ? _vm._i(_vm.selectedItems, data.item.id) > -1 : _vm.selectedItems
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.selectedItems,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = data.item.id,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && (_vm.selectedItems = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.selectedItems = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.selectedItems = $$c;
              }
            }
          }
        })])];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }), _c('div', {
    staticClass: "col-md-12 d-flex justify-content-between align-items-center"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary ml-auto mt-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.navigateToAdd
    }
  }, [_vm._v(" Submit ")])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-12 d-flex justify-content-between align-items-center"
  }, [_c('p', {
    staticStyle: {
      "font-size": "1.3rem",
      "margin-top": "15px"
    }
  }, [_vm._v("Pilih video yang ingin di tampilkan")])]);
}]

export { render, staticRenderFns }